var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ListForm',{attrs:{"headerTitle":_vm.$t('common.pages'),"onSearch":_vm.onSearch},scopedSlots:_vm._u([{key:"searchBox",fn:function(){return [_c('CForm',[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":_vm.$t('common.name'),"required":"","horizontal":""},model:{value:(_vm.searchPageName),callback:function ($$v) {_vm.searchPageName=$$v},expression:"searchPageName"}})],1),_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group form-row",attrs:{"role":"group"}},[_c('label',{staticClass:"col-sm-3 col-form-label"},[_vm._v(_vm._s(_vm.$t('common.published')))]),_c('div',{staticClass:"col-sm-9 input-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchPublished),expression:"searchPublished"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.searchPublished=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t('common.all')))]),_c('option',{domProps:{"value":true}},[_vm._v(_vm._s(_vm.$t('common.published')))]),_c('option',{domProps:{"value":false}},[_vm._v(_vm._s(_vm.$t('common.unPublished')))])])])])])],1)],1)]},proxy:true},{key:"list",fn:function(){return [_c('CDataTable',{attrs:{"items":_vm.allPages,"fields":_vm.fields,"noItemsView":{ noItems: _vm.$t('common.nodatafound') },"hover":"","striped":"","border":"","small":"","fixed":"","loading":_vm.isLoadingPages,"items-per-page":_vm.itemsPerPage,"pagination":""},scopedSlots:_vm._u([{key:"systemName",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(item.systemName))])])]}},{key:"includeInSitemap",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.includeInSitemap)?_c('CIcon',{staticClass:"text-success",attrs:{"name":"cilCheckAlt"}}):_c('CIcon',{staticClass:"text-danger",attrs:{"name":"cilXCircle"}})],1)]}},{key:"includeInMenu",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.includeInMenu)?_c('CIcon',{staticClass:"text-success",attrs:{"name":"cilCheckAlt"}}):_c('CIcon',{staticClass:"text-danger",attrs:{"name":"cilXCircle"}})],1)]}},{key:"displayOrder",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(item.displayOrder))])])]}},{key:"published",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.published)?_c('CIcon',{staticClass:"text-success",attrs:{"name":"cilCheckAlt"}}):_c('CIcon',{staticClass:"text-danger",attrs:{"name":"cilXCircle"}})],1)]}},{key:"user_detail_actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onEdit(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.edit'))+" ")]),_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onDelete(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.remove'))+" ")])],1)]}}])})]},proxy:true}])}),_c('Confirmation',{ref:"confirmation"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }