<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('common.pages')" :onSearch="onSearch">
                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="6">
                                <CInput :label="$t('common.name')"
                                        v-model ="searchPageName" required horizontal >
                                </CInput>
                            </CCol>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-sm-3 col-form-label">{{ $t('common.published') }}</label>
                                    <div class="col-sm-9 input-group">
                                        <select class="form-control" v-model="searchPublished">
                                            <option :value="null">{{ $t('common.all') }}</option>
                                            <option :value="true">{{ $t('common.published') }}</option>
                                            <option :value="false">{{ $t('common.unPublished') }}</option>
                                        </select>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                    </CForm>
                </template>
                <template v-slot:list>
                    <CDataTable :items="allPages"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                :loading="isLoadingPages"
                                :items-per-page="itemsPerPage"
                                pagination>

                        <template #systemName="{item}">
                            <td>
                                <span>{{item.systemName}}</span>
                            </td>
                        </template>

                        <template #includeInSitemap="{item}">
                            <td>
                                <CIcon name="cilCheckAlt" v-if="item.includeInSitemap" class="text-success"></CIcon>
                                <CIcon name="cilXCircle" class="text-danger" v-else></CIcon>
                            </td>
                        </template>

                        <template #includeInMenu="{item}">
                            <td>
                                <CIcon name="cilCheckAlt" v-if="item.includeInMenu" class="text-success"></CIcon>
                                <CIcon name="cilXCircle" class="text-danger" v-else></CIcon>
                            </td>
                        </template>

                        <template #displayOrder="{item}">
                            <td>
                               <span>{{item.displayOrder}}</span>
                            </td>
                        </template>

                        <template #published="{item}">
                            <td>
                                <CIcon name="cilCheckAlt" v-if="item.published" class="text-success"></CIcon>
                                <CIcon name="cilXCircle" class="text-danger" v-else></CIcon>
                            </td>
                        </template>

                        <template #user_detail_actions="{item, index}">
                            <td class="py-2">
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onEdit(item, index)">
                                    {{ $t('common.edit') }}
                                </CButton>
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onDelete(item, index)">
                                    {{ $t('common.remove') }}
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>
                </template>
            </ListForm>
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>
</template>
<script>
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import { mapGetters, mapState, mapActions } from 'vuex'
    import ListForm from '@/components/ListForm.vue'
    export default {
        name: 'CMSPageList',
        data() {
            return {
                searchPageName: '',
                searchPublished: null,

                itemsPerPage: 10,
                fields: [
                    { key: 'systemName', label: i18n.t('common.systemName') },
                    { key: 'includeInSitemap', label: i18n.t('common.includeInSitemap') },
                    { key: 'includeInMenu', label: i18n.t('common.includeInMenu') },
                    { key: 'published', label: i18n.t('common.published') },
                    { key: 'displayOrder', label: i18n.t('common.displayOrder') },
                    {
                        key: 'user_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ]
            };
        },
        components: {
            Confirmation, ListForm
        },
        computed: {
            ...mapState('cms', ['isLoadingPages', 'allPages']),
            ...mapGetters('cms', ['filteredPages']),
        },
        methods: {
            ...mapActions('cms', ['getAllPages']),
          
            async onDelete(item) {
                this.$refs.confirmation.show(i18n.t('pages.product.confirmDelete'), async () => {//
                    var retVal = await this.$store.dispatch("cms/deletePage", item.id);
                    if (retVal == true) {
                        this.searchPages();
                    }
                });
            },
            onEdit(item) {
                this.$router.push('/cms/pages/edit/' + item.id);
            },

            onSearch() {
                console.log('start search pages');
                this.searchPages();
            },
            searchPages() {
                var searchCriteria = {
                    searchPageName: this.searchPageName,
                    searchPublished: this.searchPublished,
                }
                this.$store.dispatch("cms/getAllPages", searchCriteria)
            }

        },
        mounted() {
            console.log("PAGE LIST MOUNTED")
            this.searchPages();
        }
    }
</script>